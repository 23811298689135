body {
  margin: 0;
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7fc;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  height: 50px;
  border-radius: 8px !important;
}

.back {
  top: 50px;
 left: 50px;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.imagesModal {
  padding: 24px !important;
  text-align: center;
}



@media only screen and (max-width: 600px) {
  .back {
    top: 75px;
   left: 20px;
  }
}