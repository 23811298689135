.card {
    padding: 0;
    border: 0;
}

.card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    transition: max-height 0.3s ease;
 
}

.card:hover .card-text {
    max-height: none; /* Expand to show full content on hover */
    overflow: visible;
    white-space: normal;
}

.hidden-button {
    display: none; /* Initially hidden */
    margin-top: 10px; /* Adjust as needed */
}

.card:hover .hidden-button {
    display: block; /* Show button on hover */
}




  /* App.css */

.toggle-button {
    position: absolute;
    top: 40px;
    left: 35px;
    z-index: 1000;
    cursor: pointer;
  }

  .toggle-button-mb {
   
    cursor: pointer;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    overflow-x: hidden;
    transition: 0.3s;
    background-color: #eff0f6;
    z-index: 999;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    overflow-x: hidden;
    transition: 0.3s;
    background-color: #eff0f6;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }
  
  .sidebar.open {
    width: 350px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    opacity: 0;
    z-index: 998;
    pointer-events: none;
  }
  
  .overlay.open {
    opacity: 1;
    pointer-events: auto;
  }
  
  .main-content {
    transition: margin-left 0.3s;
    padding: 16px;
  }
  
  .main-content.shifted {
    margin-left: 250px;
  }

  @media only screen and (max-width: 600px) {
    .card-text {
        white-space: wrap;
    }
    .hidden-button {
        display: block; 

    }
    .sidebar {
      width: 0;
    }
  }
  